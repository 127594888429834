<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-[550]"
  ></div>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 z-[550] flex justify-center items-center flex-col"
  >
    <PageLoader
      show-instantly
      class="!static !translate-x-0 !translate-y-0 !top-0 !left-0"
    />
    <div v-if="text" class="text-2xl font-bold text-primary-base mt-md">
      {{ t(text) }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { PageLoader } from '@/complib';
import useLoadingDialog from '@/composables/dialogs/useLoadingDialog';

const { t } = useTrans();
const dialog = useLoadingDialog();
const text = dialog.getData().loadingText;
</script>
